import { instance } from './index';

export const reportsAPI = {
  featuredFlights () {
    return instance.get('reports/featured-flights');
  },
  network (tab) {
    return instance.get(`reports/network/${tab}`);
  },
  offsetPurchases () {
    return instance.get('reports/offsetPurchases');
  },
  apRequests () {
    return instance.get('reports/apRequests');
  },
  flightReqs () {
    return instance.get('reports/flightReqs');
  },
  routeAlerts () {
    return instance.get('reports/route-alerts');
  },
  reservations () {
    return instance.get('reports/reservations');
  },
  confirmedReservations () {
    return instance.get('reports/confirmed-reservations');
  },
  bookings () {
    return instance.get('reports/bookings');
  },
  confirmations () {
    return instance.get('reports/confirmations');
  },
  flycal () {
    return instance.get('reports/flyCalRequests');
  },
  ctaEmails () {
    return instance.get('reports/ctaEmails');
  },
  subscriptions () {
    return instance.get('reports/subscriptions')
  },
  subscriptionAnalytics () {
    return instance.get('reports/subscriptionAnalytics');
  },
  discountRequests () {
    return instance.get('reports/discountRequests');
  },
  exchangeMembershipApps () {
    return instance.get('reports/exchangeMembershipApplications');
  },
}
